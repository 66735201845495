import { createRequestActionTypes } from "../../../actions";

export const READ_MANY_CREATORS_URL = `${import.meta.env.VITE_API_URL}/api/v1/users`;
export const READ_ONE_CREATOR_URL = `${import.meta.env.VITE_API_URL}/api/v1/user`;
export const INVITE_CREATOR_URL = `${import.meta.env.VITE_API_URL}/api/v1/user/invitation`;
export const UPDATE_ONE_CREATOR_URL = `${import.meta.env.VITE_API_URL}/api/v1/user`;
export const READ_MANY_CREATOR_CAMPAIGN_ASSIGNMENTS_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignments`;
export const READ_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment`;
export const CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment`;
export const UPDATE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment`;
export const DELETE_ONE_CREATOR_CAMPAIGN_ASSIGNMENT_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment/:_id`;

export const GET_CREATOR_ERROR = "Error getting creator";
export const GET_CREATORS_ERROR = "Error getting creators";
export const GET_CREATOR_ASSIGNMENTS_ERROR =
  "Error getting campaign assignment(s) for creator";
export const SAVE_CREATOR_ERROR = "Error saving creator";
export const SAVE_CREATOR_SUCCESS = "Successfully saved creator";
export const INVITE_CREATOR_ERROR = "Error inviting creator";
export const INVITE_CREATOR_SUCCESS = "Successfully sent creator invitation";
export const CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_ERROR =
  "Error assigning creator to campaign";
export const CREATE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS =
  "Successfully assigned creator to campaign";
export const REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_ERROR =
  "Error removing creator from campaign";
export const REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT_SUCCESS =
  "Successfully removed creator from campaign";
export const SAVE_CREATOR_ASSIGNMENT_ERROR = "Error saving assignment";
export const SAVE_CREATOR_ASSIGNMENT_SUCCESS =
  "Successfully saved campaign assignment for creator";

export const creatorsActionTypes = {
  inviteCreator: createRequestActionTypes("INVITE_CREATOR"),
  getCreator: createRequestActionTypes("GET_CREATOR"),
  getCreators: createRequestActionTypes("GET_CREATORS"),
  getCreatorCampaignAssignments: createRequestActionTypes(
    "GET_CREATOR_CAMPAIGN_ASSIGNMENTS",
  ),
  getCreatorCampaignAssignment: createRequestActionTypes(
    "GET_CREATOR_CAMPAIGN_ASSIGNMENT",
  ),
  updateCreator: createRequestActionTypes("UPDATE_CREATOR"),
  createCreatorCampaignAssignment: createRequestActionTypes(
    "CREATE_CREATOR_CAMPAIGN_ASSIGNMENT",
  ),
  removeCreatorCampaignAssignment: createRequestActionTypes(
    "REMOVE_CREATOR_CAMPAIGN_ASSIGNMENT",
  ),
  updateCreatorAssignment: createRequestActionTypes(
    "UPDATE_CREATOR_ASSIGNMENT",
  ),
  changeDisplayedCreator: createRequestActionTypes("CHANGE_DISPLAYED_CREATOR"),
  changeDisplayedCreatorAssignment: createRequestActionTypes(
    "CHANGE_DISPLAYED_CREATOR_ASSIGNMENT",
  ),
};

export const countryEnum = [
  { label: "United States", value: "US" },
  { label: "Canada", value: "CA" },
  { label: "United Kingdom", value: "GB" },
  { label: "Australia", value: "AU" },
  { label: "Mexico", value: "MX" },
  { label: "France", value: "FR" },
  { label: "Hong Kong", value: "HK" },
  { label: "Germany", value: "DE" },
  { label: "Italy", value: "IT" },
  { label: "Israel", value: "IL" },
  { label: "Spain", value: "ES" },
  { label: "New Zealand", value: "NZ" },
  { label: "Netherlands", value: "NL" },
  { label: "Belgium", value: "BE" },
  { label: "Austria", value: "AT" },
  { label: "Finland", value: "FI" },
  { label: "Greece", value: "GR" },
  { label: "Ireland", value: "IE" },
  { label: "Luxembourg", value: "LU" },
  { label: "Portugal", value: "PT" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
];
