import { expandAfterAutosize } from "components/DataGrid/helpers/expandAfterAutosize";
import debounce from "helpers/debounce";

/**
 * Resizes all resizable columns in the grid to fit their contents and adjusts the grid width if necessary.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gridApi - The grid API object from ag-Grid.
 * @param {boolean} [params.skipHeader] - Whether to skip resizing the header row.
 * @param {boolean} [params.fillGridWidth=true] - Whether to fill any remaining grid width after auto-sizing columns.
 * @param {Object} [params.manuallyResizedColumns={}] - Object tracking manually resized columns.
 */
const autoSizeAll = ({
  gridApi,
  skipHeader,
  fillGridWidth = true,
  manuallyResizedColumns = {},
}) => {
  if (!gridApi) {
    console.warn("No grid API found");
    return;
  }

  // Get all columns in one API call to avoid multiple calls
  const allColumns = gridApi.getColumns();
  if (!allColumns?.length) return;

  // Prepare column width updates
  const columnWidthUpdates = [];
  const columnIdsToAutoSize = [];

  // Process all columns in a single pass
  allColumns.forEach((column) => {
    const colId = column.getId();
    const colDef = column.getColDef();

    if (
      manuallyResizedColumns[colId]
      && column.getActualWidth() === manuallyResizedColumns[colId]
    ) {
      columnWidthUpdates.push({
        key: colId,
        newWidth: manuallyResizedColumns[colId],
      });
    } else if (
      colDef.resizable
      && !colDef.suppressAutoSize
      && !colDef.suppressSizeToFit
      && !manuallyResizedColumns[colId]
    ) {
      columnIdsToAutoSize.push(colId);
    }
  });

  // Batch update manually resized columns
  if (columnWidthUpdates.length > 0) {
    gridApi.applyColumnState({
      state: columnWidthUpdates.map((update) => ({
        colId: update.key,
        width: update.newWidth,
      })),
    });
  }

  // Auto-size remaining columns
  if (columnIdsToAutoSize.length > 0) {
    gridApi.autoSizeColumns(columnIdsToAutoSize, skipHeader);
  }

  // Fill remaining width if needed
  if (fillGridWidth) {
    expandAfterAutosize(gridApi, manuallyResizedColumns);
  }
};

/**
 * Debounced version of autoSizeAll that waits 300ms before executing
 * Use this version for events that can fire rapidly (like resize)
 */
export const debouncedAutoSizeAll = debounce(autoSizeAll, 300);

export default autoSizeAll;
