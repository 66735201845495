import { Suspense } from "react";

import { Outlet } from "react-router-dom";

// import { PersistenceProvider } from "providers/persistence/PersistenceProvider";

import Loader from "components/Loader";
import NavbarHeader from "components/NavbarHeader";

import { AuthGuard } from "./guards/AuthGuard";

const PrivatePageLayout = () => {
  return (
    <AuthGuard redirectTo="/login" access="private">
      {/* <PersistenceProvider> */}
      <NavbarHeader />
      <main>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      {/* </PersistenceProvider> */}
    </AuthGuard>
  );
};

export { PrivatePageLayout };
