import type { ReactNode } from "react";
import { useEffect } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { RootState } from "@/store/types";

interface AuthGuardProps {
  isAuthenticated: boolean;
  redirectTo: string;
  children: ReactNode;
  /**
   * Controls route access based on authentication state
   * @remarks
   * - 'public' - Only allows access if user is NOT authenticated. Redirects to specified path if authenticated
   * - 'private' - Only allows access if user IS authenticated. Redirects to specified path if not authenticated
   */
  access: "public" | "private";
}

/**
 * Guards routes based on authentication state
 * @param access - Whether this is a public-only or authenticated-only route
 * @param redirectTo - Where to redirect if auth requirement is not met
 */
const AuthGuard = ({
  isAuthenticated,
  redirectTo,
  access,
  children,
}: AuthGuardProps) => {
  const navigate = useNavigate();
  const shouldRedirect
    = access === "public" ? isAuthenticated : !isAuthenticated;

  useEffect(() => {
    if (shouldRedirect) {
      console.info(
        `Redirecting to ${redirectTo} - auth: ${isAuthenticated}, access: ${access}`,
      );
      navigate(redirectTo);
    }
  }, [isAuthenticated, redirectTo, access, navigate, shouldRedirect]);

  return shouldRedirect ? null : children;
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.login.isAuthenticated,
});

const ConnectedAuthGuard = connect(mapStateToProps)(AuthGuard);

export { ConnectedAuthGuard as AuthGuard };
