import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { generateKeyFromAuth, encryptionKeyManager } from "services/encryption";

import { getLoggedInUser } from "modules/Login/actions";

import type { AuthData } from "./types/types";

interface AuthProviderProps {
  getLoggedInUser: typeof getLoggedInUser;
  children: ReactNode;
}

const AuthProvider = ({ getLoggedInUser, children }: AuthProviderProps) => {
  const startedInitialCheck = useRef(false);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async() => {
      if (!startedInitialCheck.current) {
        startedInitialCheck.current = true;
        try {
          const authData = (await getLoggedInUser()) as unknown as AuthData;
          if (authData) {
            const key = await generateKeyFromAuth(authData);
            encryptionKeyManager.setKey(key);
          }
        } catch (error) {
          console.error("Auth check failed:", error);
        } finally {
          setAuthChecked(true);
        }
      }
    };

    checkAuth();
  }, [getLoggedInUser]);

  if (!authChecked) {
    return null;
  }

  return children;
};

const ConnectedAuthProvider = connect(null, { getLoggedInUser })(AuthProvider);

export { ConnectedAuthProvider as AuthProvider };
