import { createTransform } from "redux-persist";
import type { RootState } from "store/types";

import { encrypt, decrypt } from "../../services/encryption";

/**
 * Redux-persist transform that encrypts data before storage and decrypts on load
 */
export const encryptionTransform = createTransform(
  // inbound (state to storage)
  (inboundState: RootState | undefined) => {
    try {
      if (!inboundState) return inboundState;
      const encrypted = encrypt(inboundState);
      return encrypted;
    } catch (error) {
      console.error("Failed to encrypt state:", error);
      return undefined; // Don't persist on encryption failure
    }
  },

  // outbound (storage to state)
  (outboundState) => {
    try {
      if (!outboundState) return outboundState;
      if (typeof outboundState !== "string") {
        // If not encrypted (e.g. initial state), return as is
        return outboundState;
      }
      const decrypted = decrypt(outboundState);
      return decrypted;
    } catch (error) {
      console.error("Failed to decrypt state:", error);
      return undefined; // Return undefined on decryption failure
    }
  },
  {
    whitelist: ["api"], // Only encrypt the RTK Query cache
  },
);
