import debounce from "helpers/debounce";

/**
 * Resizes resizable columns in the grid to take up any remaining grid width if necessary,
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @param {Object} manuallyResizedColumns - Object tracking manually resized columns
 */
export const expandAfterAutosize = (gridApi, manuallyResizedColumns) => {
  if (!gridApi) {
    console.warn("expandAfterAutosize: gridApi is required");
    return;
  }

  const allDisplayedColumns = gridApi.getAllDisplayedColumns();
  if (!allDisplayedColumns.length) {
    console.warn("expandAfterAutosize: No displayed columns found");
    return;
  }

  // Get the grid's root element width
  const gridElement = gridApi?.gridBodyCtrl?.eBodyViewport;
  if (!gridElement) {
    console.warn("expandAfterAutosize: Grid element not found");
    return;
  }

  const gridWidth = gridElement.offsetWidth;
  let totalColumnWidth = 0;
  const resizableColumnsInfo = [];

  // Process all columns in a single pass
  allDisplayedColumns.forEach((column) => {
    const colWidth = column.getActualWidth();
    totalColumnWidth += colWidth;

    const colDef = column.getColDef();
    if (
      colDef?.resizable
      && !colDef?.suppressSizeToFit
      && !colDef?.suppressAutoSize
      && !manuallyResizedColumns[column.getColId()]
    ) {
      resizableColumnsInfo.push({ colId: column.getColId(), colWidth });
    }
  });

  const remainingWidth = gridWidth - totalColumnWidth;
  if (remainingWidth <= 0 || resizableColumnsInfo.length === 0) return;

  const totalResizableWidth = resizableColumnsInfo.reduce(
    (sum, col) => sum + col.colWidth,
    0,
  );
  if (totalResizableWidth === 0) return;

  const scalingFactor
    = (totalResizableWidth + remainingWidth) / totalResizableWidth;
  if (scalingFactor <= 1.01) return; // Avoid resizing if the difference is negligible

  // Batch update all column widths at once
  gridApi.applyColumnState({
    state: resizableColumnsInfo.map(({ colId, colWidth }) => ({
      colId,
      width: Math.floor(colWidth * scalingFactor),
    })),
  });
};

/**
 * Debounced version of expandAfterAutosize that waits 300ms before executing
 * Use this version for events that can fire rapidly (like resize)
 */
export const debouncedExpandAfterAutosize = debounce(expandAfterAutosize, 300);
