import { memo } from "react";

import { AssignmentLink } from "@/features/assignments";
import SelectAdminSigner from "features/agreements/components/Agreement/SelectAdminSigner";

export const agreementFieldDefs = [
  {
    fieldKey: "_id",
    type: "text",
    hideCondition: () => {
      return true;
    },
  },
  {
    fieldKey: "title",
    type: "text",
    label: "Title",
    creatorVisible: true,
    editing: {
      required: true,
      creatorVisible: null,
      label: null,
      componentProps: { label: "Title", creatorVisible: true },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.status;
      },
    },
  },
  {
    // TODO: custom status renderer
    fieldKey: "status",
    type: "text",
    label: "Status",
    creatorVisible: true,
    editing: {
      creatorVisible: null,
      label: null,
      componentProps: { label: "Status", creatorVisible: true },
      // CustomRenderer: (props) => (
      //   <CustomSelect
      //     {...props}
      //     name="status_viewonly"
      //     options={Object.values(agreementsStatusDict).map((status) => ({
      //       label: status.label,
      //       value: status.value,
      //     }))}
      //   />
      // ),
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.status;
      },
    },
  },
  {
    fieldKey: "isAdminActionRequired",
    // type: "boolean",
    label: "Action Required (Admin)",
    creatorVisible: true,
    type: "switch",
    editing: {
      creatorVisible: null,
      label: null,
      componentProps: {
        label: "Action Required (Admin)",
        creatorVisible: true,
      },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.status;
      },
    },
  },
  {
    fieldKey: "assignment_id",
    type: "text",
    label: "Assignment",
    hideCondition: (values, data) => {
      return !data?._id || !values?._id || !values?.assignment_id;
    },
    CustomRenderer: memo(AssignmentLink),
  },
  // TODO: campaign_id
  // TODO: brand_id
  // TODO: user_id
  // TODO: assignment_id
  {
    fieldKey: "files",
    type: "uploader",
    label: "Document/File",
    creatorVisible: true,
    fieldInputProps: { multiple: true, accept: "application/pdf" },
    hideCondition: (values) => {
      return values?._id;
    },
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: { label: "Document/File", creatorVisible: true },
    },
  },
  {
    fieldKey: "title",
    type: "text",
    label: "Title",
    creatorVisible: true,
    editing: {
      required: true,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Title", creatorVisible: true },
      disableCondition: (values) => {
        return values?._id;
      },
      hideCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "isRequired",
    type: "switch",
    label: "Required for Payment",
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Require for Payment",
      },
      defaultValue: false,
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "adminHasSent",
    type: "switch",
    label: "Admin Has Sent",
    editing: {
      label: null,
      componentProps: { label: "Admin Has Sent", creatorVisible: true },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id;
      },
    },
  },
  {
    fieldKey: "requiresCreatorSignature",
    type: "switch",
    label: "Require Creator Signature",
    creatorVisible: true,
    editing: {
      defaultValue: true,
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Require Creator Signature",
        creatorVisible: true,
      },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "creatorHasSigned",
    type: "switch",
    label: "Creator Has Signed",
    creatorVisible: true,
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Creator Has Signed",
        creatorVisible: true,
      },
      defaultValue: false,
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.requiresCreatorSignature;
      },
    },
  },
  {
    fieldKey: "requiresAdminSignature",
    type: "switch",
    label: "Require Admin Signature",
    creatorVisible: true,
    editing: {
      defaultValue: false,
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Require Admin Signature",
        creatorVisible: true,
      },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "adminHasSigned",
    type: "switch",
    label: "Admin Has Signed",
    creatorVisible: true,
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Admin Has Signed",
        creatorVisible: true,
      },
      defaultValue: false,
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.requiresAdminSignature;
      },
    },
  },
  {
    fieldKey: "adminSigner_id",
    label: "Admin Signer",
    creatorVisible: false,
    // displayValue: displayedCampaign?.brand_id?.name,
    type: "text",
    editing: {
      defaultValue: null,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Admin Signer", creatorVisible: true },
      fieldKey: "adminSigner_id",
      CustomRenderer: memo(SelectAdminSigner),
      hideCondition: (values) => {
        return !values?.requiresAdminSignature || values?._id;
      },
    },
  },
  {
    fieldKey: "adminEmailAddress",
    type: "text",
    label: "Admin Signer",
    creatorVisible: true,
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: { label: "Admin Signer", creatorVisible: true },
      hideCondition: (values) => {
        return !values?.requiresAdminSignature || !values?._id;
      },
      disableCondition: () => {
        return true;
      },
    },
  },
  {
    fieldKey: "message",
    type: "textarea",
    label: "Message",
    creatorVisible: true,
    editing: {
      defaultValue: null,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Message", creatorVisible: true },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "dueDate",
    type: "date",
    label: "Due Date",
    creatorVisible: true,
    editing: {
      defaultValue: null,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Due Date", creatorVisible: true },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
];
