import { memo } from "react";

import { LinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/LinkCellRenderer";

/**
 * Renders a link to a brand based on the provided grid cell value.
 *
 * @param {object} props
 * @param {string} props.value - The ID of the brand. Passed from ag-Grid.
 * @param {object} props.data - The row data of the brand. Passed from ag-Grid.
 * @returns {JSX.Element} The rendered BrandLink component.
 */
const BrandLinkCellRendererBase = ({ value, data }) => {
  let text;
  let muted = true;

  if (!value) {
    text = "";
  } else if (!data?.brand_id?.name && !data?.name) {
    text = "Can not display brand data.";
  } else {
    text = data.brand_id?.name || data.name;
    muted = false;
  }

  return <LinkCellRenderer to={`/brands/${value}`} text={text} muted={muted} />;
};

export const BrandLinkCellRenderer = memo(BrandLinkCellRendererBase);
