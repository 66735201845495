import { useMemo } from "react";

import clsx from "clsx";

import { AgreementsGrid } from "features/agreements2/components/AgreementsGrid";

export const CampaignAgreements = ({ campaign_id, className, ...props }) => {
  const classes = clsx("campaign-agreements", className);
  const filterParams = useMemo(() => {
    return {
      campaign_id,
    };
  }, [campaign_id]);
  return (
    <AgreementsGrid
      filterParams={filterParams}
      className={classes}
      gridId="campaign-agreements"
      excludeDataSources={"campaign"}
      // HeaderActionElem={<AssignCreator campaign_id={campaign_id} />}
    />
  );
};
