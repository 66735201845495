import { memo } from "react";

import BoolIndicator from "components/StatusIndicators/BoolIndicator";

const BoolCellRenderer = ({
  value,
  truthyText = "",
  falsyText = "",
  applyColor = true,
  ...props
}) => {
  return (
    <BoolIndicator
      value={value}
      truthyText={truthyText}
      falsyText={falsyText}
      type="icon"
      applyColor={applyColor}
    />
  );
};

export default memo(BoolCellRenderer);
