/**
 * A custom React hook that returns the number of rows in a DataGrid component.
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @returns {Object} An object containing the number of rows in the DataGrid component and event handlers to update the row count when the grid is rendered, when row data is updated, and when the filter is changed.
 */

import { useState, useCallback } from "react";

import debounce from "helpers/debounce";

const debouncedUpdateRowCount = debounce((api, setCount) => {
  if (!api) return;
  const count = api.getDisplayedRowCount();
  setCount(count);
}, 100);

export const useRowCount = (gridApi) => {
  const [filteredCount, setFilteredCount] = useState(null);

  const updateRowCount = useCallback(() => {
    debouncedUpdateRowCount(gridApi, setFilteredCount);
  }, [gridApi]);

  return {
    filteredCount,
    totalCount: filteredCount, // TODO: Just using the same count for both, but should return separate counts
    onFirstDataRendered: updateRowCount,
    onGridReady: updateRowCount,
    // onViewportChanged: updateRowCount,
    onRowDataUpdated: updateRowCount,
    onFilterChanged: updateRowCount,
  };
};
