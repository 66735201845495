import { Outlet } from "react-router-dom";

import { AuthGuard } from "./guards/AuthGuard";

export const PublicPageLayout = () => {
  return (
    <AuthGuard redirectTo="/dashboard" access="public">
      <main className="d-flex vh-100 align-items-center">
        <Outlet />
      </main>
    </AuthGuard>
  );
};
