import { StrictMode } from "react";

import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { store } from "store/store";

import App from "./App";
import { initializeTelemetry, FaroErrorBoundary } from "./telemetry";
import "styles/main.scss";

initializeTelemetry();

try {
  const rootElement = document.getElementById("root");
  if (!rootElement) throw new Error("Failed to find the root element");
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <FaroErrorBoundary>
      <StrictMode>
        <Provider store={store}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Provider>
      </StrictMode>
    </FaroErrorBoundary>,
  );
} catch (error) {
  console.error("Initialization error:", error);
  throw error;
}
