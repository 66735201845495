import { memo, useState } from "react";

import { Input, InputGroup } from "reactstrap";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import debounce from "helpers/debounce";
import "./index.scss";

/**
 * @param {function(string): void} onChange
 */
const createDebouncedChange = (onChange) =>
  debounce((value) => onChange(value), 300);

/** @type {function({ onChange: function(string): void, value: string }): JSX.Element} */
const QuickFilterComponent = ({ onChange, value: externalValue }) => {
  const [localValue, setLocalValue] = useState(externalValue);
  const debouncedOnChange = createDebouncedChange(onChange);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <InputGroup
      className={`quick-filter ${localValue && "quick-filter--applied"}`}
    >
      <div className="input-group-text">
        <MagnifyingGlassIcon height={18} width={18} strokeWidth={2.5} />
      </div>
      <XMarkIcon
        height={18}
        width={18}
        strokeWidth={2}
        className="quick-filter__clear text-secondary"
        // onClick={() => onChange("")}
      />
      <Input
        className="quick-filter__input"
        name="filter"
        type="text"
        value={localValue}
        onChange={handleChange}
      />
    </InputGroup>
  );
};

const QuickFilter = memo(QuickFilterComponent);
QuickFilter.displayName = "QuickFilter";

export default QuickFilter;
