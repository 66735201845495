import { CampaignLinkCellRenderer } from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";

export const invoicesColDefs = [
  {
    headerName: "_id",
    field: "_id",
    hide: true,
  },
  {
    headerName: "Invoice ID",
    field: "invoiceId",
  },
  {
    headerName: "Brand",
    field: "brand_id._id",
    dataSource: "brand",
    cellRenderer: BrandLinkCellRenderer,
    type: "text",
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.brand_id?.name) {
        return "";
      }
      return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
    },
  },
  {
    headerName: "Campaign",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: CampaignLinkCellRenderer,
    filterValueGetter: (params) => {
      // Makes the campaign's name and ID searchable
      if (!params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.campaign_id?.name} ${params.data?.campaign_id?._id}`;
    },
  },
  {
    headerName: "Campaign Short ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: CampaignLinkCellRenderer,
    cellRendererParams: {
      linkTextKey: "shortId",
    },
    filterValueGetter: (params) => {
      return params.data?.shortId;
    },
  },
  {
    headerName: "Department",
    field: "campaign_id.department",
    dataSource: "campaign",
    type: "text",
    valueGetter: (params) => {
      let text = "";
      if (params.data?.campaign_id?.department === "managed") {
        text = "Managed Services";
      }
      if (params.data?.campaign_id?.department === "talent") {
        text = "Talent";
      }
      return text;
    },
  },
  {
    headerName: "Status",
    field: "status",
    valueFormatter: (params) => {
      return params?.value?.charAt(0)?.toUpperCase() + params?.value?.slice(1);
    },
  },
  {
    headerName: "Email Status",
    field: "EmailStatus",
    valueFormatter: (params) => {
      return params?.value?.replace(/([A-Z])/g, " $1")?.trim();
    },
  },
  {
    headerName: "Invoice Amount",
    field: "TotalAmt",
    type: "currency",
  },
  {
    headerName: "Unpaid",
    field: "Balance",
    type: "currency",
  },
  {
    headerName: "Due Date",
    field: "DueDate",
    type: "date",
  },
];
