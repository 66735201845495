import type { PersistConfig } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import createIdbStorage from "redux-persist-indexeddb-storage";
import createCompressor from "redux-persist-transform-compress";

import type rootReducer from "../../reducers";
import { apiTransform } from "../transforms/apiTransform";
import { encryptionTransform } from "../transforms/encryptionTransform";

const CACHE_VERSION = 0.2;

const storage = createIdbStorage("nraportal");
const compressor = createCompressor({
  // whitelist: [apiSlice.reducerPath],
  whitelist: [],
});

export const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: "root",
  version: CACHE_VERSION,
  storage,
  stateReconciler: autoMergeLevel1,
  debug: !import.meta.env.PROD,
  // whitelist: [apiSlice.reducerPath],
  whitelist: [],
  transforms: [apiTransform, compressor, encryptionTransform],
};
