import { memo } from "react";

import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Button } from "reactstrap";

/** @type {import('react').FC<{to: string, text: string, muted?: boolean}>} */
const BaseLinkCellRenderer = ({ to, text, muted }) => {
  const linkClassName = clsx(
    "ag-cell-value p-0 w-100 text-start text-decoration-none",
    {
      "small text-muted": muted,
      "link-accent-dark": !muted,
    },
  );

  return (
    <Button tag={NavLink} color="link" className={linkClassName} to={to}>
      {text}
    </Button>
  );
};

export const LinkCellRenderer = memo(BaseLinkCellRenderer);
