import { memo } from "react";

import { LinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/LinkCellRenderer";

/**
 * Renders a link to a creator based on the provided grid cell value.
 *
 * @param {Object} props
 * @param {string} props.value - The ID of the creator. Passed from ag-Grid.
 * @param {object} props.data - The row data of the creator. Passed from ag-Grid.
 * @returns {JSX.Element} The rendered CreatorLink component.
 */
const CreatorLinkCellRendererBase = ({ value, data }) => {
  let text;
  let muted = true;

  if (!value) {
    text = "";
  } else if (!data?.user_id?.name && !data?.name) {
    text = "Can not display creator data.";
  } else {
    text = data.user_id?.name || data.name;
    muted = false;
  }
  return (
    <LinkCellRenderer to={`/creators/${value}`} text={text} muted={muted} />
  );
};

export const CreatorLinkCellRenderer = memo(CreatorLinkCellRendererBase);
